export const useValidation = () => {
  const buildStringValidation = (msg: string) => {
    return [(v: string) => !!v || msg]
  }

  return {
    buildStringValidation,
    requiredName: buildStringValidation("Please enter your first and last name"),
    requiredPhone: [(val: string) => /^\d{10}$/.test(val) || "Please enter a valid phone number"],
    requiredPlate: buildStringValidation("Please enter the license plate number"),
    requiredPlateState: buildStringValidation("Please select the license plate state"),
    requiredVin: [(val: string) => /^[A-Z0-9]{17}$/.test(val) || 'Please enter a valid VIN'],
    requiredYear: buildStringValidation("Please select a year"),
    requiredMake: buildStringValidation("Please select a make"),
    requiredModel: buildStringValidation("Please select a model"),
    requiredTrim: buildStringValidation("Please select a trim"),
    requiredCondition: buildStringValidation("Please select a condition"),
    requiredMileage: buildStringValidation("Please enter the mileage"),
    postalCodeRules: [
      (v: string) => !!v || "Please enter the postal code",
      (v: string) => /^[0-9]{5}$/.test(v) || "Please enter a valid 5-digit postal code"
    ],
    requiredFinanceType: buildStringValidation("Please select how your vehicle is financed"),
    requiredLenderName: buildStringValidation("Please select a lender"),
    requiredFinanceInquiryType: buildStringValidation("Please select a way to look up your loan information"),
    requiredFinanceInquiryValue: buildStringValidation("Required"),
    requiredFinanceInquiryConsent: [(v: boolean) => v || "Please agree to the terms and conditions"],
    requiredFinancePayoffAmount: [(v: string) => {
      const parsed = parseInt(v.toString().replace(/\D/g, ""))
      return (v && parsed > 0) || "Please enter a valid payoff amount"
    }],
    // note: regex from https://emailregex.com/
    requiredEmail: [(v: string) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Please enter a valid email address"],
    phoneFormat: [(v: string) => /^\(\d{3}\)\s\d{3}-\d{4}$/.test(v) || "Please enter a valid phone number"],
    requiredYesNoAnswer: buildStringValidation("Please answer this question"),
    requiredColorDetail: [(v: []) => {
      return v && v.length > 0 || "Please select your vehicle's color"
    }],
    validateSsn: [
      (v: string) => !!v || "Please enter a valid SSN",
      (v: string) => /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/.test(v) || "Please enter a valid SSN"
    ],
  }
}

export default useValidation